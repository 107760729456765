import React, { useEffect, useState } from "react";
import Layout from "../components/layout";
import { graphql } from "gatsby";
import Seo from "../components/seo";
import { Link } from "gatsby";

const Blog = ({ data, location }) => {
  const [posts, setPost] = useState(data.allMarkdownRemark.nodes);
  const [category, setCategory] = useState("");
  const siteTitle = data.site.siteMetadata?.title || `Title`;
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const category = params.get("category");
    setCategory(category);
    if (!category) {
      setPost(data.allMarkdownRemark.nodes);
    }
    if (category) {
      let post = data.allMarkdownRemark.nodes.filter((data) => {
        return data.frontmatter.category === category;
      });
      setPost(post);
    }
  }, [location, data, category]);

  if (posts.length === 0) {
    return (
      <Layout location={location} title={siteTitle.toString()}>
        <Seo title="Blog" />
        <section
          style={{ minHeight: "90vh" }}
          className="blog-listing gray-bg pt-5 mt-5"
        >
          <div className="container">
            <div className="row justify-content-center align-item-center text-center">
              <h5 style={{ lineHeight: "2em" }}>No blogs found.</h5>
            </div>
          </div>
        </section>
      </Layout>
    );
  }
  return (
    <Layout location={location} title={siteTitle.toString()}>
      <Seo title="All blog" />
      <section className="blog-listing gray-bg">
        <div className="container">
          {/* section title */}
          <div className="row justify-content-md-center">
            <div className="col-xl-5 col-lg-6 col-md-8">
              <div className="section-title text-center title-ex1">
                <h2>Blog</h2>
                <p></p>
              </div>
            </div>
          </div>
          <ul className="nav nav-tabs justify-content-center" role="tablist">
            <li
              role="presentation"
              className={`${!category ? "active mb-2" : "mb-2"}`}
            >
              <Link
                className={`${!category ? "active show" : "show"}`}
                to="/blog"
                aria-controls="all"
                role="tab"
                data-toggle="tab"
                aria-selected="true"
              >
                All
              </Link>
            </li>
            <li
              role="presentation"
              className={`${category === "angular" ? "active mb-2" : "mb-2"}`}
            >
              <Link
                className={`${category === "angular" ? "active show" : "show"}`}
                to="/blog/?category=angular"
                aria-controls="angular"
                role="tab"
                data-toggle="tab"
                aria-selected="true"
              >
                Angular
              </Link>
            </li>
            <li
              role="presentation"
              className={`${category === "react" ? "active mb-2" : "mb-2"}`}
            >
              <Link
                className={`${category === "react" ? "active show" : "show"}`}
                to="/blog/?category=react"
                aria-controls="react"
                role="tab"
                data-toggle="tab"
                aria-selected="false"
              >
                React
              </Link>
            </li>
            <li
              role="presentation"
              className={`${category === "nodejs" ? "active mb-2" : "mb-2"}`}
            >
              <Link
                className={`${category === "nodejs" ? "active show" : "show"}`}
                to="/blog/?category=nodejs"
                aria-controls="nodejs"
                role="tab"
                data-toggle="tab"
                aria-selected="false"
              >
                Node JS
              </Link>
            </li>
            <li
              role="presentation"
              className={`${category === "python" ? "active mb-2" : "mb-2"}`}
            >
              <Link
                className={`${category === "python" ? "active show" : "show"}`}
                to="/blog/?category=python"
                aria-controls="python"
                role="tab"
                data-toggle="tab"
                aria-selected="false"
              >
                Python
              </Link>
            </li>
            <li
              role="presentation"
              className={`${category === "docker" ? "active mb-2" : "mb-2"}`}
            >
              <Link
                className={`${category === "docker" ? "active show" : "show"}`}
                to="/blog/?category=docker"
                aria-controls="docker"
                role="tab"
                data-toggle="tab"
                aria-selected="false"
              >
                Docker
              </Link>
            </li>
            <li
              role="presentation"
              className={`${category === "firebase" ? "active mb-2" : "mb-2"}`}
            >
              <Link
                className={`${
                  category === "firebase" ? "active show" : "show"
                }`}
                to="/blog/?category=firebase"
                aria-controls="firebase"
                role="tab"
                data-toggle="tab"
                aria-selected="false"
              >
                Firebase
              </Link>
            </li>
            <li
              role="presentation"
              className={`${
                category === "react-native" ? "active mb-2" : "mb-2"
              }`}
            >
              <Link
                className={`${
                  category === "react-native" ? "active show" : "show"
                }`}
                to="/blog/?category=react-native"
                aria-controls="react-native"
                role="tab"
                data-toggle="tab"
                aria-selected="false"
              >
                React Native
              </Link>
            </li>
            <li
              role="presentation"
              className={`${category === "aws" ? "active mb-2" : "mb-2"}`}
            >
              <Link
                className={`${category === "aws" ? "active show" : "show"}`}
                to="/blog/?category=aws"
                aria-controls="aws"
                role="tab"
                data-toggle="tab"
                aria-selected="false"
              >
                AWS
              </Link>
            </li>
            <li
              role="presentation"
              className={`${category === "mongodb" ? "active mb-2" : "mb-2"}`}
            >
              <Link
                className={`${category === "mongodb" ? "active show" : "show"}`}
                to="/blog/?category=mongodb"
                aria-controls="mongodb"
                role="tab"
                data-toggle="tab"
                aria-selected="false"
              >
                MongoDB
              </Link>
            </li>
            <li role="presentation">
              <div className="btn-group">
                <button type="button" className="btn btn-sm">
                  More
                </button>
                <button
                  type="button"
                  className="btn btn-sm dropdown-toggle dropdown-toggle-split"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <span className="visually-hidden">Toggle Dropdown</span>
                </button>
                <ul className="dropdown-menu">
                  <li>
                    <Link
                      className="dropdown-item"
                      to="/blog/?category=javascript"
                    >
                      JavaScript
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="dropdown-item"
                      to="/blog/?category=typescript"
                    >
                      TypeScript
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/blog/?category=nextjs">
                      Next JS
                    </Link>
                  </li>
                </ul>
              </div>
            </li>
          </ul>
          {/* section title ends */}
          <div className="row align-items-start">
            <div className="col-lg-12 m-15px-tb">
              <div className="row">
                {posts.map((post, key) => {
                  const title = post.frontmatter.title || post.fields.slug;
                  return (
                    <div key={key} className="col-sm-4">
                      <div className="blog-grid">
                        <div className="blog-img">
                          {/*<div className="date">{post.frontmatter.date}</div>*/}
                          <Link to={post.fields.slug}>
                            <img
                              src={post.frontmatter.bannerimage}
                              title
                              alt=""
                            />
                          </Link>
                        </div>
                        <div className="blog-info">
                          <h5>
                            <Link to={post.fields.slug} itemProp="url">
                              {title.length > 50
                                ? title.substring(0, 50).concat("...")
                                : title}
                            </Link>
                          </h5>
                          <p
                            dangerouslySetInnerHTML={{
                              __html:
                                post.frontmatter.description.length > 105
                                  ? post.frontmatter.description
                                      .substring(0, 105)
                                      .concat("...")
                                  : post.frontmatter.description ||
                                    post.excerpt,
                            }}
                            itemProp="description"
                          />
                          <div className="btn-bar">
                            <Link
                              className="px-btn-arrow"
                              to={post.fields.slug}
                              itemProp="url"
                            >
                              <span>Read More</span>
                              <i className="arrow" />
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Blog;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      filter: { fileAbsolutePath: { glob: "**/blog/**" } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
          category
          bannerimage
        }
      }
    }
  }
`;
